<template>
    <div>
        <product></product>
        <shopping-cart></shopping-cart>
    </div>
</template>

<script>
import Product from '@/components/Product';
import ShoppingCart from '../components/ShoppingCart.vue';

export default {
    name: 'Menu',
    components: {
        Product,
        ShoppingCart,
    },
};
</script>

<style scoped></style>
